import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import Cookie from 'js-cookie';
import shortid from 'shortid';
import Modal from 'react-modal';
import qs from 'qs';
import ReactTooltip from 'react-tooltip';
import ImageUploader from 'react-images-upload';
import moment from 'moment-timezone';
import InputWithLabel from '../InputWithLabel';
import SignUpSkills from '../SignUpSkills';

import { useSystemTogglesContext } from '../../context/SystemToggles';
import { useUserProfile } from '../../context/UserProfile';
import { replaceUnderscoreWithSpaces } from '../../utils/text';
import HelpIcon from '@mui/icons-material/Help';
import {
  ValueToggle,
  VolunteerProfile,
  UserProfile,
  Partner,
} from '../../types';
import './profile-create.scss';
import Availability from './Availability';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useWindowSize from '../../hooks/useWindowSize';
import { IconButton, Tooltip } from '@mui/material';
import Tag from '../../atoms/Tag';
import { DashboardFilledButton } from '../../styling/buttons';
import { useSelector } from 'react-redux';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';
const timeZoneList = moment.tz.names();
timeZoneList.push('');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

const validatePostCode = (
  selectedPostCode: string,
  selectedCountry: string
) => {
  // validate UK post codes
  const re = /^[A-Za-z]{1,2}\d[A-Za-z\d]?( ?\d[A-Za-z]{2})?$/;

  if (selectedCountry === 'GBR' && !re.test(selectedPostCode)) {
    return false;
  } else {
    return true;
  }
};
/*
They can enter this page by signing up either with LinkedIn or email/password.

if they enter through LinkedIn there will be a 'code' parameter in the query string.
With linkedin we will need to capture their email, but we will not need to capture first/last name.

*/

const ProfileCreate = () => {
  const { toggles } = useSystemTogglesContext();
  const autoDetectTimezone = toggles['TIMEZONE_AUTO_DETECT'];
  const { setUserProfileContext, setVolunteerProfileContext, userProfile } =
    useUserProfile();
  const partner = useSelector(selectPartnerProfile);
  const { fetchAllProfileData } = useSelectProfileData();
  let linkedInCode = qs.parse(
    window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
  ).code;
  let linkedInState = qs.parse(
    window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
  ).state;
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);
  const [showTooltip4, setShowTooltip4] = useState(false);
  const [showTooltip5, setShowTooltip5] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  const [section, setSection] = useState('name');
  const [errorMessage, setErrorMessage] = useState<string | boolean>(false);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [country, setCountry] = useState<string>('GBR');
  const [connectedPartner, setConnectedPartner] = useState<null | Partner>(
    null
  );

  const [postCode, setPostCode] = useState<string>('');
  const [GDPRPermissionGiven, setGDPRPermissionGiven] =
    useState<boolean>(false);

  const [GDPREmailPermissionGiven, setGDPREmailPermissionGiven] =
    useState<boolean>(false);

  const [partnerId, setPartnerId] = useState<string | undefined | number>(
    partner?.id
  );

  const maxSkills: ValueToggle = toggles.MAX_SKILLS as ValueToggle;
  const minSkills: ValueToggle = toggles.MIN_SKILLS as ValueToggle;
  const [disableSelect, setDisableSelect] = useState(false);
  const [minNotSelected, setMinNotSelected] = useState(false);

  const profilePhotoEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const firstNameEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const lastNameEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const bioEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const genderEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const locationEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const roleEl = useRef() as React.MutableRefObject<HTMLInputElement>;

  const isPartnerEmail = (userEmail: string | undefined) => {
    api.get('api/partner/partners/?limit=1000').then((response) => {
      response.data.results.forEach((partner: any) => {
        if (partner?.recognised_emails) {
          partner?.recognised_emails
            .split(';')
            .forEach((partnerEmail: string) => {
              if (userEmail?.includes(partnerEmail.trim())) {
                setConnectedPartner(partner);
              }
            });
        }
      });
    });
  };

  const getLinkedIn = () => {
    api
      .get(
        `api/core/linkedin_callback/?code=${linkedInCode}&state=${linkedInState}&user_type=volunteer`,
        {}
      )
      .then((response: any) => {
        // set cookie that expires in a day
        Cookie.set('refreshTokenDB', response.data.refresh, {
          expires: 1,
        });

        Cookie.set('accessTokenDB', response.data.access, {
          expires: 1,
        });
      })
      .catch((err: any) => console.log(err));
    setSection('location');
  };

  const getProfile = () => {
    if (
      Cookie.get('accessTokenDB') === undefined ||
      Cookie.get('refreshTokenDB') === undefined
    ) {
      navigate('/signup');
    }

    api.get('api/volunteer/volunteers/me/').then((response) => {
      setFirstName(response.data.first_name);
      setSection('skills');
      // setEmail(true);
    });
  };

  const getCountries = async () => {
    try {
      const response = (await api.get(
        `api/location/countries/?limit=1000`,
        {}
      )) as any;
      setCountryList(response.data.results);
    } catch (e) {
      console.error(e);
    }
  };

  const getMyLocation = async () => {
    if (
      Cookie.get('accessTokenDB') === undefined ||
      Cookie.get('refreshTokenDB') === undefined
    ) {
      navigate('/signup');
    }
    try {
      const response = await api.get(`api/location/locations/me/`);

      if (response.status !== 404) {
        setCountry(response.data.country);
        setPostCode(response.data.postcode);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getEthnicityList = () => {
    return api
      .get(`api/ethnicity/ethnicity/?limit=1000`, {})
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => err);
  };

  useEffect(() => {
    getCountries();
    getMyLocation();

    if (linkedInCode) {
      getLinkedIn();
    } else {
      getProfile();
    }

    if (typeof Cookie.get('partner_id') === 'string') {
      setPartnerId(Cookie.get('partner_id'));
      setGDPRPermissionGiven(Cookie.get('gdpr_permission_required') === 'true');
    } else {
      isPartnerEmail(userProfile?.username);
    }

    getEthnicityList().then((response) => {
      setEthnicityList(response.data.results);
    });
    // eslint-disable-next-line
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [summary, setSummary] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [newsletter, setNewsletter] = useState(true);
  const [currentCompany, setCurrentCompany] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
  const [timezone, setTimezone] = useState<string>(
    browserTimezone || 'Europe/London'
  );

  const [skills, setSkills] = useState<any[]>([]);

  const [isProfilePhotoSet, setIsProfilePhotoSet] = useState(false);

  const navigate = useNavigate();

  const [availabilityTime, setAvailability] = useState({
    days: [0, 1, 2, 3, 4, 5, 6],
    time: 0,
    start_hour: 8,
    end_hour: 20,
    start_minute: 0,
    end_minute: 0,
    ongoing_mentoring: true,
    one_off_mentoring: true,
  });

  const [gender, setGender] = useState<string>('');
  const [showPhoto, setShowPhoto] = useState<string | undefined>(undefined);
  const [ethnicity, setEthnicity] = useState<number>();
  const [ethnicityList, setEthnicityList] = useState<any[]>([]);
  const [customEthnicity, setCustomEthnicity] = useState<string>('');
  const [profilePhotoError, setProfilePhotoError] = useState<
    string | undefined
  >(undefined);
  const [nameError, setNameError] = useState<boolean>(false);
  const [bioError, setBioError] = useState<boolean>(false);
  const [genderError, setGenderError] = useState<boolean>(false);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<boolean>(false);
  const [postCodeValidationError, setPostCodeValidationError] =
    useState<boolean>(false);

  const onClickNext = useCallback(() => {
    if (section === 'name') {
      if (
        !isProfilePhotoSet ||
        !firstName ||
        !lastName ||
        !gender ||
        !summary
      ) {
        let elToFocus;
        if (!isProfilePhotoSet) {
          setProfilePhotoError('Please upload a photo');
          elToFocus = profilePhotoEl;
        }
        if (!gender) {
          setGenderError(true);
          elToFocus = genderEl;
        }
        if (!summary) {
          setBioError(true);
          elToFocus = bioEl;
        }
        if (!firstName || !lastName) {
          setNameError(true);
          elToFocus = firstNameEl;
        }

        elToFocus?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        return;
      }
      setSection('currentCompany');
      return window.scrollTo(0, 0);
    }
    if (section === 'currentCompany') {
      if (!currentCompany || !currentRole) {
        setRoleError(true);
        roleEl?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        return;
      }
      setSection('location');
      return window.scrollTo(0, 0);
    }
    if (section === 'location') {
      if (
        !postCode ||
        !country ||
        (!!postCode && !validatePostCode(postCode, country))
      ) {
        if (postCode && !validatePostCode(postCode, country))
          setPostCodeValidationError(true);
        if (!postCode || !country) setLocationError(true);
        roleEl?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        return;
      }
      setSection('skills');
      return window.scrollTo(0, 0);
    }
    if (section === 'skills') {
      if (minSkills && minSkills.enabled) {
        if (skills.length < minSkills.value) {
          setMinNotSelected(true);
        } else {
          setSection('availability');
          window.scrollTo(0, 0);
        }
      } else {
        setSection('availability');
        window.scrollTo(0, 0);
      }
      setSection('availability');
      return window.scrollTo(0, 0);
    }
    if (section === 'availability') {
      setModalIsOpen(true);
      return window.scrollTo(0, 0);
    }
  }, [
    country,
    summary,
    currentCompany,
    currentRole,
    firstName,
    firstNameEl,
    gender,
    genderEl,
    isProfilePhotoSet,
    lastName,
    minSkills,
    postCode,
    profilePhotoEl,
    roleEl,
    section,
    skills.length,
  ]);

  const updateProfilePhoto = async (files: (string | Blob)[]) => {
    const formData = new FormData();
    formData.append('profile_picture', files[0]);
    setLoadingPhoto(true);
    setProfilePhotoError(undefined);
    try {
      await api
        .patch(`api/core/users/me/`, formData)
        .then((response: any) => {
          setShowPhoto(response.data.profile_picture);
          setIsProfilePhotoSet(true);
        })
        .catch((err: any) => {
          const profilePicError =
            err.response &&
            err.response.data &&
            err.response.data.profile_picture
              ? err.response.data.profile_picture[0] ===
                'The submitted data was not a file. Check the encoding type on the form.'
                ? "Sorry, we don't accept that file format. Please ensure your file is in one of the formats listed above and try again"
                : err.response.data.profile_picture[0]
              : 'An error occurred while updating the profile picture. Please try a different photo.';

          setProfilePhotoError(profilePicError);
        })
        .finally(() => setLoadingPhoto(false));
    } catch (e) {
      console.error(e);
    }
  };

  const updateCoreUser = () => {
    let formData = new FormData();
    formData.append('gender', gender);

    api
      .patch<UserProfile>(`api/core/users/me/`, formData)
      .then((userProfileResponse) => {
        setUserProfileContext({
          userProfile: userProfileResponse.data,
          hasFinishedUserProfileFetch: true,
        });
      })
      .catch((err: any) => console.log(err));
  };

  const onDrop = (files: any) => {
    console.log('Profile Image:', files);
    if (files !== undefined && files.length !== 0) updateProfilePhoto(files);
  };

  const submitData = async () => {
    let availabilityIdArray: number[] = [];

    skills.forEach((skill) => {
      availabilityIdArray.push(skill.id);
    });

    let sendObject: any = {};

    // change the fields we send based on if the user signed in with LinkedIn or not
    if (linkedInCode) {
      sendObject = {
        interests: availabilityIdArray,
        privacy_preferences: { newsletter: newsletter },
        availability: { period: 'week', minutes: availabilityTime.time },
        one_off_mentoring: availabilityTime.one_off_mentoring,
        ongoing_mentoring: availabilityTime.ongoing_mentoring,
      };

      // if they have been told to ask for GDPR permission and they have given it then link them to the partner.
      // if the partner has told them to be linked and told them not to be asked then just link them.
      if (GDPRPermissionGiven && partnerId !== undefined) {
        if (typeof partnerId === 'string') {
          sendObject.partner_page = parseInt(partnerId);
          sendObject.partner = partnerId;
        } else {
          sendObject.partner_page = partnerId;
          sendObject.partner = partnerId;
        }
      }

      if (
        (connectedPartner?.gdpr_permission_required &&
          GDPREmailPermissionGiven) ||
        (connectedPartner && !connectedPartner.gdpr_permission_required)
      ) {
        sendObject.partner = connectedPartner.id;
      }

      api
        .patch<VolunteerProfile>(`api/volunteer/volunteers/me/`, sendObject)
        .then(async () => {
          await fetchAllProfileData();
          Cookie.remove('gdpr_permission_required', { path: '/' });
          Cookie.remove('partner_id', { path: '/' });
          Cookie.remove('partner_name', { path: '/' });

          navigate(`/signup-complete/?name=${firstName}`);
        })
        .catch((err: any) => {
          if (err.response.data['non_field_errors']) {
            setErrorMessage(err.response.data['non_field_errors']);
          } else {
            setErrorMessage('Server Error');
          }
        });
    } else {
      sendObject = {
        first_name: firstName,
        partner: partner?.id ? partner.id : null,
        last_name: lastName,
        current_role: currentRole,
        employer: currentCompany,
        interests: availabilityIdArray,
        availability: { period: 'week', minutes: availabilityTime.time },
        one_off_mentoring: availabilityTime.one_off_mentoring,
        ongoing_mentoring: availabilityTime.ongoing_mentoring,
        privacy_preferences: { newsletter: newsletter },
        ethnicity: ethnicity,
        custom_ethnicity: customEthnicity,
      };
      // if they have been told to ask for GDPR permission and they have given it then link them to the partner.
      // if the partner has told them to be linked and told them not to be asked then just link them.
      if (partnerId && GDPRPermissionGiven) {
        sendObject.partner_page = partnerId;
        sendObject.partner = partnerId;
      }

      if (
        (connectedPartner?.gdpr_permission_required &&
          GDPREmailPermissionGiven) ||
        (connectedPartner && !connectedPartner.gdpr_permission_required)
      ) {
        sendObject.partner = connectedPartner.id;
      }
      const sendSummaryObject = {
        first_name: firstName,
        last_name: lastName,
        summary: summary,
      };
      await api
        .patch<UserProfile>(`api/core/users/me/`, sendSummaryObject)
        .catch((err: any) => {
          if (err.response.data['non_field_errors']) {
            setErrorMessage(err.response.data['non_field_errors']);
          } else {
            setErrorMessage('Server Error');
          }
        });

      api
        .post<VolunteerProfile>(`api/volunteer/volunteers/`, sendObject)
        .then(async () => {
          await fetchAllProfileData();
        })
        .catch((err: any) => {
          if (err.response.data['non_field_errors']) {
            setErrorMessage(err.response.data['non_field_errors']);
          } else {
            setErrorMessage('Server Error');
          }
        });

      updateCoreUser();
      
      const location = { country: country, postcode: postCode };
      api
        .patch(`api/location/locations/me/`, location);
    }
  };

  const completeSignUp = async () => {
    await api
      .patch(`api/volunteer/volunteers/me/`, { availability: availabilityTime })
      .then((volunteerProfileResponse) => {
        setVolunteerProfileContext({
          volunteerProfile: volunteerProfileResponse.data,
          hasFinishedVolunteerProfileFetch: true,
        });
      })
      .catch((err: any) => console.log(err));
    navigate(`/signup-complete/?name=${firstName}`);
  };
  const handleCheckboxClick = (id: number, label: string) => {
    setMinNotSelected(false);
    let newSkills: any[] = [...skills];
    let indexToRemove: number | undefined;

    if (disableSelect) {
      setDisableSelect(false);
    }

    newSkills.forEach((skill, index) => {
      if (skill.id === id) {
        indexToRemove = index;
      }
    });

    if (indexToRemove !== undefined) {
      newSkills.splice(indexToRemove, 1);
    } else {
      newSkills.push({
        id: id,
        label: label,
      });
    }

    if (maxSkills && maxSkills.enabled) {
      if (newSkills.length - 1 < maxSkills.value) {
        setSkills(newSkills);
      } else {
        setDisableSelect(true);
      }
    } else {
      setSkills(newSkills);
    }
  };

  const ethnicityGroups = ethnicityList.filter((a) => a.parent === null);
  const ethnicityOptgroup = ethnicityGroups.map((item) => {
    const ethnicitySubGroup = ethnicityList.filter((a) => a.parent === item.id);
    const ethnicityOptions = ethnicitySubGroup.map((data) => {
      return (
        <option
          key={`ethnicity-${data.id}`}
          defaultValue={ethnicity}
          value={data.id}
        >
          {data.name}
        </option>
      );
    });

    return (
      <optgroup key={`ethnicity-group-${item.id}`} label={item.name}>
        {ethnicityOptions}
      </optgroup>
    );
  });
  return (
    <div className="profile-create">
      <div className="error"> {errorMessage}</div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Newsletter"
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="newsletter">
          <ReactTooltip />
          <div className="error"> {errorMessage}</div>
          <h1>One Last Thing</h1>
          <div>
            Would you like to join our newsletter to keep updated and connected?
            (We won’t be annoying)
          </div>
          <div>
            <input
              checked={newsletter}
              type={'checkbox'}
              onClick={() => setNewsletter(!newsletter)}
              readOnly={true}
            />
            <label> I would like to receive newsletters</label>
          </div>
          {connectedPartner?.gdpr_permission_required && (
            <div
              onClick={() =>
                setGDPREmailPermissionGiven(!GDPREmailPermissionGiven)
              }
              className="login-signup__terms-container"
            >
              <input
                checked={GDPREmailPermissionGiven}
                type={'checkbox'}
                readOnly={true}
                className="login-signup__terms-checkbox"
              />
              <label>
                {' '}
                {connectedPartner.custom_gdpr_text ? (
                  <>{connectedPartner.custom_gdpr_text}</>
                ) : (
                  <>
                    I agree to share my performance analytics with{' '}
                    <b>{connectedPartner.name} </b>
                  </>
                )}{' '}
                (
                <span
                  className="login-signup__data-tip"
                  data-tip={
                    connectedPartner.custom_gdpr_explainer
                      ? connectedPartner.custom_gdpr_explainer
                      : `If you click this button, you agree that we can share with ${connectedPartner.name} how many people you mentored and how many hours you mentored them. You are free at any time to update your preferences if you no longer wish us to share this information with ${connectedPartner.name}`
                  }
                >
                  What is shared and why?
                </span>
                )
              </label>
            </div>
          )}
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '250px', marginTop: '10px' }}
            onClick={() => submitData().then(() => completeSignUp())}
          >
            Complete Signup
          </DashboardFilledButton>

          <div
            className="back-link"
            onClick={() => {
              setModalIsOpen(false);
            }}
          >
            Back: Availability
          </div>
        </div>
      </Modal>

      {section === 'name' && (
        <div>
          <h1>Great! Let’s set up your profile, it’s simple </h1>

          <div className="profile-create__photo-container" ref={profilePhotoEl}>
            {showPhoto && (
              <div
                style={{
                  backgroundImage: `url(${showPhoto})`,
                }}
                className="profile-picture-volunteer"
              />
            )}
            {loadingPhoto ? (
              <div style={{ fontFamily: 'Gilroy', fontSize: '14px' }}>
                Loading, please wait...
              </div>
            ) : (
              <div
                style={{
                  display: 'inline-flex',
                  alignContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <ImageUploader
                  withIcon={false}
                  withPreview={true}
                  label=""
                  singleImage={true}
                  buttonText="Upload Profile Photo"
                  onChange={onDrop}
                  imgExtension={[
                    '.jpeg',
                    '.jpg',
                    '.gif',
                    '.png',
                    '.gif',
                    '.svg',
                    '.peg',
                  ]}
                  maxFileSize={2248576}
                  fileSizeError="File size is too big, please keep it below 2 MB"
                />
                <div style={{ fontFamily: 'Gilroy', fontSize: '12px' }}>
                  Accepted file formats: .jpeg, .jpg, .gif, .png, .svg, .peg
                </div>
                <div style={{ fontFamily: 'Gilroy', fontSize: '12px' }}>
                  Accepted file size up to 2 MB
                </div>
              </div>
            )}
            {profilePhotoError && (
              <span className="profile-create__validation-error">
                {profilePhotoError}
              </span>
            )}
          </div>
          <div>What should we call you?</div>

          <div>
            <div ref={firstNameEl}>
              <InputWithLabel
                type="text"
                required={true}
                label={'First Name'}
                maxLength={30}
                placeHolder={'Your First Name '}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (nameError) setNameError(false);
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div ref={lastNameEl}>
              <InputWithLabel
                type="text"
                required={true}
                label={'Last Name'}
                maxLength={30}
                placeHolder={'Your Last Name '}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (nameError) setNameError(false);
                  setLastName(e.target.value);
                }}
              />
            </div>
            {nameError && (
              <span className="profile-create__validation-error">
                {'First Name and Last Name are required fields'}
              </span>
            )}
            <div className="intro" ref={bioEl}>
              <InputWithLabel
                required={true}
                onInput={(e: React.FormEvent<HTMLSelectElement>) => {
                  if (bioError) setBioError(false);
                  setSummary(e.currentTarget.value);
                }}
                defaultValue={summary}
                type={'text'}
                label={'Your Introduction'}
                isMobile={isMobile}
                muiTooltip={{
                  showMuiTooltip: showTooltip5,
                  clickMuiTooltip: () => setShowTooltip5(true),
                  closeMuiTooltip: () => setShowTooltip5(false),
                  muiTooltipText:
                    'We include an introduction so that your mentees can get to know you! We recommend sharing a couple of sentences telling everyone who you are, what help you can give, and a fun fact or two to break the ice.',
                  muiTooltipId: 'introdution-tooltip',
                }}
              />
            </div>
            {bioError && (
              <span className="linkedin-signup__validation-error">
                {'Please provide a brief introduction'}
              </span>
            )}
            <div className="radio-wrapper" ref={genderEl}>
              <label className="gender-label required-field-with-asterisk">
                Gender
                <div>*</div>
                <Tooltip
                  open={showTooltip1}
                  onClick={() => setShowTooltip1(true)}
                  onClose={() => setShowTooltip1(false)}
                  leaveTouchDelay={isMobile ? 5000 : 1500}
                  placement={isMobile ? 'top-start' : 'right'}
                  title="Please state which gender identity you most identify with. Providing your gender data is optional but it will help us better match you to a suitable learner in need."
                  id="gender-tooltip"
                >
                  <IconButton
                    sx={{
                      fontSize: 'small',
                      maxWidth: '20px',
                      marginLeft: '10px',
                    }}
                  >
                    <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                  </IconButton>
                </Tooltip>
              </label>
              <div className="gender-options">
                <label>
                  <input
                    type="radio"
                    value="female"
                    checked={gender === 'female'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (genderError) setGenderError(false);
                      setGender(e.target.value);
                    }}
                  />
                  <span>Woman</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="male"
                    checked={gender === 'male'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (genderError) setGenderError(false);
                      setGender(e.target.value);
                    }}
                  />
                  <span>Man</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="non-binary"
                    checked={gender === 'non-binary'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (genderError) setGenderError(false);
                      setGender(e.target.value);
                    }}
                  />
                  <span>Non Binary</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="dont-identify"
                    checked={gender === 'dont-identify'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (genderError) setGenderError(false);
                      setGender(e.target.value);
                    }}
                  />
                  <span>I don't identify with any of these genders</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="other"
                    checked={gender === 'other'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (genderError) setGenderError(false);
                      setGender(e.target.value);
                    }}
                  />
                  <span>Prefer not to say</span>
                </label>
              </div>
              {genderError && (
                <span className="profile-create__validation-error">
                  {'Please choose a value'}
                </span>
              )}
            </div>

            <div className="select-ethnicity">
              <label className="ethnicity-label">
                Ethnicity
                <Tooltip
                  open={showTooltip2}
                  onClick={() => setShowTooltip2(true)}
                  onClose={() => setShowTooltip2(false)}
                  leaveTouchDelay={isMobile ? 5000 : 1500}
                  placement={isMobile ? 'top-start' : 'right'}
                  title=" Your ethnicity helps us improve the quality of our matches. We also use this data to report usage of the service anonymously to our partners. "
                  id="ethnicity-tooltip"
                >
                  <IconButton
                    sx={{
                      fontSize: 'small',
                      maxWidth: '20px',
                      marginLeft: '10px',
                    }}
                  >
                    <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                  </IconButton>
                </Tooltip>
              </label>
              <select
                value={ethnicity}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setEthnicity(parseInt(e.currentTarget.value));
                  if (
                    ethnicityList.filter((a) => a.id === ethnicity).length >
                      0 &&
                    !ethnicityList.filter((a) => a.id === ethnicity)[0]
                      .allow_custom
                  ) {
                    setCustomEthnicity('');
                  }
                }}
              >
                <option
                  key="ethnicity-empty"
                  defaultValue={ethnicity}
                  value=""
                ></option>
                {ethnicityOptgroup}
              </select>
              {ethnicityList.filter((a) => a.id === ethnicity).length > 0 &&
                ethnicityList.filter((a) => a.id === ethnicity)[0]
                  .allow_custom && (
                  <InputWithLabel
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCustomEthnicity(e.target.value)
                    }
                    type="text"
                    maxlength={255}
                    placeHolder="Enter the ethnicity you indentify with"
                    defaultValue={customEthnicity}
                  />
                )}
            </div>
          </div>
          <DashboardFilledButton
            variant="contained"
            onClick={onClickNext}
            sx={{ width: '250px', marginTop: '10px' }}
          >
            Next: Experience
          </DashboardFilledButton>
        </div>
      )}
      {section === 'currentCompany' && (
        <div>
          <h1>Welcome, {firstName} ! Great to see you here. </h1>
          <div>
            Enter your role and company information to help us better allocate
            resources. You can change them anytime.
          </div>
          <div ref={roleEl}>
            <InputWithLabel
              type="text"
              required={true}
              label={'Your Employer'}
              maxLength={30}
              placeHolder={"Enter your company's name"}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (roleError) setRoleError(false);
                setCurrentCompany(e.target.value);
              }}
            />
            <InputWithLabel
              type="text"
              required={true}
              label={'Your Role'}
              placeHolder={'e.g. Digital Marketing Director '}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (roleError) setRoleError(false);
                setCurrentRole(e.target.value);
              }}
            />
            {roleError && (
              <span className="profile-create__validation-error">
                {'Employer and Role are required fields'}
              </span>
            )}
          </div>
          <DashboardFilledButton
            variant="contained"
            onClick={onClickNext}
            sx={{ width: '250px', marginTop: '10px' }}
          >
            Next: Location
          </DashboardFilledButton>

          <div
            className="back-link"
            onClick={() => {
              setSection('name');
              window.scrollTo(0, 0);
            }}
          >
            Back: Name
          </div>
        </div>
      )}
      {section === 'location' && (
        <div>
          <h1>Where are you from?</h1>
          <div ref={locationEl}>
            Enter your country and postcode information to help us better
            allocate resources. You can change them anytime.
          </div>
          <div>We will not make this information visible.</div>
          <div className="input-container">
            <div className="select-country">
              <label>Country of Residence</label>
              <select
                value={country}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  if (locationError) setLocationError(false);
                  if (postCodeValidationError)
                    setPostCodeValidationError(false);
                  setCountry(e.currentTarget.value);
                }}
              >
                {countryList.map((displayCountry, i) => {
                  return (
                    <option key={`country-${i}`} value={displayCountry.code}>
                      {displayCountry.name}
                    </option>
                  );
                })}
              </select>
              <div>
                <label>Postcode</label>
                <Tooltip
                  open={showTooltip4}
                  onClick={() => setShowTooltip4(true)}
                  onClose={() => setShowTooltip4(false)}
                  leaveTouchDelay={isMobile ? 5000 : 1500}
                  placement={isMobile ? 'top-start' : 'right'}
                  title="Only the first three digits are required. This information will help us make more relevant matches and is used for reporting purposes."
                  id="postcode-tooltip"
                >
                  <IconButton
                    sx={{
                      fontSize: 'small',
                      maxWidth: '20px',
                      marginLeft: '10px',
                    }}
                  >
                    <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                  </IconButton>
                </Tooltip>
                <InputWithLabel
                  type={'text'}
                  required={true}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (locationError) setLocationError(false);
                    setPostCode(e.target.value);
                  }}
                  value={postCode}
                />
              </div>
              {autoDetectTimezone ? (
                <div className="profile-create__timezone-container">
                  <label>
                    Timezone
                    <Tooltip
                      open={showTooltip3}
                      onClick={() => setShowTooltip3(true)}
                      onClose={() => setShowTooltip3(false)}
                      leaveTouchDelay={isMobile ? 5000 : 1500}
                      placement={isMobile ? 'top-start' : 'right'}
                      title="Timezone is automatically detected based on your device settings. Times will be displayed according to this timezone."
                      id="timezone-tooltip"
                    >
                      <IconButton
                        sx={{
                          fontSize: 'small',
                          maxWidth: '20px',
                          marginLeft: '10px',
                        }}
                      >
                        <HelpIcon
                          sx={{ fontSize: 'small', color: '#2f80ed' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <span className="profile-create__timezone-text">
                    {replaceUnderscoreWithSpaces(timezone)}
                  </span>
                </div>
              ) : (
                <>
                  <label>Timezone</label>
                  <select
                    value={timezone}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                      setTimezone(e.currentTarget.value)
                    }
                  >
                    {timeZoneList.map((tz, i: number) => {
                      return (
                        <option key={`timezone-${i}`} value={tz}>
                          {replaceUnderscoreWithSpaces(tz)}
                        </option>
                      );
                    })}
                  </select>
                </>
              )}
            </div>
            {locationError && (
              <span className="profile-create__validation-error">
                {'Postcode is a required field'}
              </span>
            )}
            {postCodeValidationError && (
              <span className="profile-create__validation-error">
                {'You must provide a valid postcode'}
              </span>
            )}
          </div>
          <div className="profile-create__button-container">
            <DashboardFilledButton
              variant="contained"
              onClick={onClickNext}
              sx={{ width: '250px', marginTop: '10px' }}
            >
              Next: Skills
            </DashboardFilledButton>
          </div>

          {!linkedInCode && (
            <div
              className="back-link"
              onClick={() => {
                setSection('currentCompany');
                window.scrollTo(0, 0);
              }}
            >
              Back: Experience
            </div>
          )}
        </div>
      )}
      {section === 'skills' && (
        <div className="profile-create__skills-container">
          <h1>
            Amazing {firstName.length > 0 ? firstName : userProfile?.first_name}
            . What would you like to help with?{' '}
          </h1>
          <div> Select subjects you can speak about and support with</div>
          <div>
            <div className="tag-container">
              {skills.map((skill) => {
                return skill ? (
                  <Tag
                    key={shortid.generate()}
                    text={skill.label}
                    type="secondary"
                  />
                ) : null;
              })}
            </div>
          </div>
          <SignUpSkills
            handleCheckboxClick={handleCheckboxClick}
            skillsList={skills}
          />
          {disableSelect && (
            <p className="error-state">{`You can only select up to ${
              maxSkills.value
            } skill${minSkills.value === 1 ? '' : 's'}`}</p>
          )}
          {minNotSelected && (
            <p className="error-state">{`You need to select at least ${
              minSkills.value
            } skill${minSkills.value === 1 ? '' : 's'}`}</p>
          )}
          <div className="profile-create__button-container">
            <DashboardFilledButton
              variant="contained"
              onClick={() => {
                if (minSkills && minSkills.enabled) {
                  if (skills.length < minSkills.value) {
                    setMinNotSelected(true);
                  } else {
                    window.scrollTo(0, 0);
                    setSection('availability');
                    window.scrollTo(0, 0);
                  }
                } else {
                  window.scrollTo(0, 0);
                  setSection('availability');
                  window.scrollTo(0, 0);
                }
              }}
              sx={{ width: '250px', marginTop: '10px' }}
            >
              Next: Availability
            </DashboardFilledButton>
          </div>
          <div
            className="back-link"
            onClick={() => {
              setSection('location');
              window.scrollTo(0, 0);
            }}
          >
            Back: Location
          </div>
        </div>
      )}
      {section === 'availability' && (
        <div>
          <h1>Almost there...</h1>
          <div>
            Set your availability, so we can send you the right number of
            mentoring requests. You can change your availability anytime or
            decline requests you can't help with at any time.
          </div>
          <Availability
            setAvailability={setAvailability}
            availability={availabilityTime}
            setModalIsOpen={setModalIsOpen}
          />
          <div className="profile-create__button-container"></div>
          <div
            className="back-link"
            onClick={() => {
              setSection('skills');
              window.scrollTo(0, 0);
            }}
          >
            Back: Specialties
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCreate;
