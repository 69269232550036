import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import useGetCallProviders from '../../apiHooks/coreData/useGetCallProviders';
import { TimeSlot } from '../../types';
import { defaultTimeFormat } from '../../utils/constants';
import { displayTime } from '../../utils/time';
import './style.scss';
import useWindowSize from '../../hooks/useWindowSize';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CallConfirmedPage from './callConfirmedPage';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { useNavigate } from 'react-router-dom';
import MenteeDetailsModal from './menteeDetailsModal';
import Cookie from 'js-cookie';
import { getUrlParam, validateUrl } from '../../utils/url';
import { confirmBoostCallInvite } from '../../apiCalls/boostCalls';
import CloseIcon from '@mui/icons-material/Close';
import ProposeNewTime from './proposeNewTime';
import OrganisationNotFound from '../../templates/PageNotFound/organisationNotFound';
import moment from 'moment';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
  TimeSlotButton,
} from '../../styling/buttons';
import BusinessHealthTooltip from '../../atoms/BusinessHealthTooltip.tsx';
import { CustomOrganisationNameTypography } from '../../styling/generalStyling';
import useProposeNewTime from '../../apiHooks/boostCallInvites/useProposeNewTime';
import { selectSessionTitle } from '../../apiHooks/sessionTitle';
import { useSelector } from 'react-redux';
import { startMessage } from '../../api/messagingCentre';
type Props = {
  event: any;
  type?: string;
};
const NewCallDetails = ({ event, type }: Props) => {
  const navigate = useNavigate();
  const { volunteerProfile, hasFinishedLoadingVolunteerProfile } =
    useSelectProfileData();
  const action = getUrlParam('action') as string;
  const volunteerId = volunteerProfile?.id;
  const [newTime, setNewTime] = useState<Date | null>(null);
  if (event.status === 'pending' && action === 'decline')
    navigate(`/decline-call?code=${event.invite.code}`);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const proposedNewTime = event.time_slots?.find(
    ({ status, volunteer }: TimeSlot) =>
      status === 'time_slot_proposed' && volunteer === volunteerId
  );

  const [displayRequest, setDisplayRequest] = React.useState(
    event.help_needed.length > 150
      ? event.help_needed.slice(0, 150)
      : event.help_needed
  );
  const updateDisplayRequest = () => setDisplayRequest(event.help_needed);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isDirectedRequest = event.invited_volunteers?.includes(volunteerId);
  var skillLevel = 'I know nothing';
  if (event.interest_expertise === 'i_know_a_lot')
    skillLevel = 'I know a lot, just need a help with some specifics';
  else if (event.interest_expertise === 'i_know_some')
    skillLevel = 'I know some';

  const { callProviderDropDownOptions } = useGetCallProviders();
  const [callConfirmed, setCallConfirmed] = useState(undefined);
  const [acceptRequestInProgress, setAcceptRequestInProgress] = useState(false);
  const [callProvider, setSelectedCallProvider] = useState('');
  const [callLink, setCallLink] = React.useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const changeCallLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCallLink(event.target.value);
  };
  const [messageError, setMessageError] = React.useState<string | null>(null);
  const volunteerAccepted =
    event.time_slots.filter(
      (timeslot: { status: string }) =>
        timeslot.status === 'time_slot_scheduled'
    ) || null;
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [timeSlotErrorMessage, setTimeSlotErrorMessage] = useState<
    null | string
  >(null);
  const timeSlotId =
    (getUrlParam('time_slot') as string) || Cookie.get('timeSlotId');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | undefined>(
    timeSlotId ? Number(timeSlotId) : undefined
  );
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  const { confirmCall } = confirmBoostCallInvite();
  const [proposeNewTimeModal, setProposeNewTimeModal] = useState(
    action === 'propose_new_time' ? true : false
  );
  const handleErrorMessage = (message: string) => {
    setTimeSlotErrorMessage(message);
  };
  const { proposeNewTime, setProposedStartDate, proposeNewTimeError } =
    useProposeNewTime({
      code: event.invite?.code,
      defaultCallProvider: callProvider,
    });
  const closeConfirmation = () => {
    setConfirmationModal(false);
    window.location.reload();
  };
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  useEffect(() => {
    if (
      hasFinishedLoadingVolunteerProfile &&
      volunteerProfile?.partnerInfo?.custom_call_provider
    )
      setSelectedCallProvider(
        volunteerProfile?.partnerInfo?.custom_call_provider.id.toString()
      );
  }, [volunteerProfile, hasFinishedLoadingVolunteerProfile]);

  const confirmNewProposedTime = async () => {
    setErrorMessage(null);
    if (
      callProvider.length > 0 &&
      callProvider !== '3' &&
      !validateUrl(callLink)
    )
      setErrorMessage('The link is not valid');
    else {
      setAcceptRequestInProgress(true);
      if (newTime) setProposedStartDate(newTime);
      setAcceptRequestInProgress(true);

      await proposeNewTime(newTime, callLink)
        .then(() => setConfirmationModal(true))
        .catch((error) => {
          console.log(error);
        });

      setAcceptRequestInProgress(false);
    }
  };
  const handleSendMessage = async () => {
    setIsLoading(true);
    try {
      await startMessage(event.organisation.id);
      navigate(`/messaging-centre?id=${event?.organisation?.id}`);
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        navigate(`/messaging-centre?id=${event?.organisation?.id}`);
      } else {
        setMessageError(
          "Sorry we're having some trouble connecting you. Please try again later or message our team for support"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const hasAvailableSlots =
    event.time_slots?.filter(
      (timeSlot: { volunteer: null; status: string; start_time: string }) =>
        timeSlot.status === 'time_slot_pending'
    )?.length > 0;
  const sessionTitle = useSelector(selectSessionTitle);
  if (event.organisation === null) return <OrganisationNotFound />;
  else if (callConfirmed) return <CallConfirmedPage event={callConfirmed} />;
  else
    return (
      <div>
        <div className="organisation-information-container">
          <div className="header-container">
            <div className="header-text">
              {sessionTitle} with
              <CustomOrganisationNameTypography
                sx={{}}
                className="organisation-first-name"
                onClick={() => setIsModalOpen(true)}
              >
                {event.organisation?.first_name}
              </CustomOrganisationNameTypography>
            </div>
            <DashboardEmptyButton
              sx={{ fontSize: '14px' }}
              variant="text"
              onClick={() => setIsModalOpen(true)}
            >
              View learner details
            </DashboardEmptyButton>
          </div>
          <div className="organisation-information-paper">
            <div className="organisation-information-category-title">
              Requested by
            </div>
            <div className="organisation-information-photo">
              <div
                onClick={() => setIsModalOpen(true)}
                className="profile-photo-card"
                style={{
                  cursor: 'pointer',
                  backgroundImage: event.organisation?.profile_picture
                    ? `url(${event.organisation.profile_picture})`
                    : 'url(/avatar.png)',
                }}
              ></div>
              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="organisation-information-category-title">
                    {event.organisation.first_name}
                  </div>
                </div>
                <div className="manage-call-organisation">
                  <div style={{ marginBottom: '10px' }}>
                    {' '}
                    {event.organisation?.name}
                  </div>
                  {event.organisation.business_health ? (
                    <BusinessHealthTooltip
                      business_health={event.organisation.business_health}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="organisation-information-category-title">
              Needs help in
            </div>
            <div className="organisation-information-category-subtext">
              {event.top_priority.name || event.top_priority_name}
            </div>
            <div className="organisation-information-category-title">
              {event.organisation.first_name}'s request
            </div>
            <div className="organisation-information-category-subtext">
              {displayRequest.length < event.help_needed.length
                ? displayRequest + ' ...'
                : event.help_needed}

              {displayRequest.length < event.help_needed.length ? (
                <Button
                  variant="text"
                  sx={{
                    textTransform: 'none',
                    width: '100px',
                    alignSelf: 'flex-end',
                    color: '#2f80ed',
                  }}
                  onClick={() => updateDisplayRequest()}
                >
                  View more
                </Button>
              ) : null}
            </div>

            <div className="organisation-information-category-title">
              Current skill level
            </div>
            <div className="organisation-information-category-subtext">
              {skillLevel}
            </div>
          </div>
          {event.status === 'scheduled' ? (
            <div className="organisation-information-category-title">
              {event.status === 'scheduled' &&
              volunteerAccepted.filter(
                (slot: { volunteer_accepted: number }) =>
                  slot?.volunteer_accepted === volunteerId
              )?.length > 0 ? (
                <div> This request is scheduled. Thank you!</div>
              ) : (
                <>
                  {!event?.allow_multiple_sessions ? (
                    <div>
                      {' '}
                      Sorry, this session has already been accepted by another
                      volunteer. Thanks for volunteering!
                    </div>
                  ) : (
                    <div className="call-details-step">
                      <div className="cicle-counter">1</div>

                      {proposedNewTime ? (
                        <div className="display-on-column">
                          <div className="organisation-information-category-title">
                            You proposed the following time
                          </div>

                          <TimeSlotButton
                            variant="contained"
                            sx={{ color: 'white' }}
                          >
                            {displayTime(
                              proposedNewTime.start_time,
                              undefined,
                              `DD MMM ${defaultTimeFormat}`
                            )}
                          </TimeSlotButton>
                        </div>
                      ) : (
                        <div>
                          {newTime ? (
                            <div className="display-on-column">
                              <div className="organisation-information-category-title">
                                You are proposing the following time
                              </div>
                              <div>
                                <TimeSlotButton
                                  variant="contained"
                                  sx={{ color: 'white' }}
                                  onClick={() => {
                                    setNewTime(null);
                                    setProposeNewTimeModal(true);
                                  }}
                                >
                                  {displayTime(
                                    newTime.toString(),
                                    undefined,
                                    `DD MMM ${defaultTimeFormat}`
                                  )}
                                </TimeSlotButton>
                                <DashboardEmptyButton
                                  variant="outlined"
                                  sx={{
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    width: '223px',
                                    borderRadius: '8px',
                                    border: 'solid 1px primary',
                                    height: ' 38px',
                                  }}
                                  onClick={() => {
                                    setNewTime(null);
                                    setProposeNewTimeModal(true);
                                  }}
                                >
                                  <div className="call-provider-subtext">
                                    {' '}
                                    Select New Time
                                  </div>
                                </DashboardEmptyButton>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {hasAvailableSlots && (
                                <div className="organisation-information-category-title">
                                  Select one of the following times
                                </div>
                              )}

                              {hasAvailableSlots ? (
                                <div className="organisation-information-category-subtext">
                                  {event.organisation.first_name} provided these
                                  timeslots for the session.
                                </div>
                              ) : (
                                <div className="organisation-information-category-subtext">
                                  The timeslots provided by{' '}
                                  {event.organisation.first_name} are no longer
                                  available, but you can still propose a
                                  different one
                                </div>
                              )}

                              <div className="call-details-time-slots">
                                {event.time_slots
                                  ?.filter(
                                    (timeSlot: {
                                      volunteer: null;
                                      status: string;
                                      start_time: string;
                                    }) =>
                                      moment(timeSlot.start_time) > moment() &&
                                      timeSlot.status === 'time_slot_pending'
                                  )
                                  .map((timeSlot: TimeSlot) => (
                                    <TimeSlotButton
                                      variant="contained"
                                      sx={{
                                        backgroundColor:
                                          selectedTimeSlot === timeSlot.id
                                            ? 'primary'
                                            : 'transparent',
                                        color:
                                          selectedTimeSlot !== timeSlot.id
                                            ? '#333'
                                            : 'contrastText',
                                      }}
                                      onClick={() =>
                                        setSelectedTimeSlot(timeSlot.id)
                                      }
                                    >
                                      {displayTime(
                                        timeSlot.start_time,
                                        undefined,
                                        `DD MMM ${defaultTimeFormat}`
                                      )}
                                    </TimeSlotButton>
                                  ))}

                                <DashboardEmptyButton
                                  variant="outlined"
                                  sx={{
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    width: '223px',
                                    borderRadius: '8px',
                                    border: 'solid 1px primary',
                                    height: ' 38px',
                                  }}
                                  onClick={() => {
                                    setProposeNewTimeModal(true);
                                  }}
                                >
                                  <div className="call-provider-subtext">
                                    {' '}
                                    Select New Time
                                  </div>
                                </DashboardEmptyButton>
                              </div>

                              <ProposeNewTime
                                event={event}
                                setNewTime={setNewTime}
                                setProposeNewTimeModal={setProposeNewTimeModal}
                                proposeNewTimeModal={proposeNewTimeModal}
                              />
                               <div style={{ marginTop: '10px', fontSize: '14px', fontWeight: 'normal', }}>
                            If you can't find a timeslot that works,
                            <button
                              onClick={handleSendMessage}
                              style={{
                                fontFamily: 'Gilroy',
                                background: 'none',
                                border: 'none',
                                color: '#007BFF',
                                marginLeft: '3px',
                                marginRight: '3px',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontSize: '14px',
                                fontWeight: 'normal',
                                padding: '0',
                                outline: 'none',
                              }}
                            >
                              click here
                            </button>
                            to message the mentee and agree a time to meet.
                          </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="call-details-step">
              <div className="cicle-counter">1</div>

              {proposedNewTime ? (
                <div className="display-on-column">
                  <div className="organisation-information-category-title">
                    You proposed the following time
                  </div>

                  <TimeSlotButton variant="contained" sx={{ color: 'white' }}>
                    {displayTime(
                      proposedNewTime.start_time,
                      undefined,
                      `DD MMM ${defaultTimeFormat}`
                    )}
                  </TimeSlotButton>
                </div>
              ) : (
                <div>
                  {newTime ? (
                    <div className="display-on-column">
                      <div className="organisation-information-category-title">
                        You are proposing the following time
                      </div>
                      <div>
                        <TimeSlotButton
                          variant="contained"
                          sx={{ color: 'white' }}
                          onClick={() => {
                            setNewTime(null);
                            setProposeNewTimeModal(true);
                          }}
                        >
                          {displayTime(
                            newTime.toString(),
                            undefined,
                            `DD MMM ${defaultTimeFormat}`
                          )}
                        </TimeSlotButton>
                        <DashboardEmptyButton
                          variant="outlined"
                          sx={{
                            fontSize: '17px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            width: '223px',
                            borderRadius: '8px',
                            border: 'solid 1px primary',
                            height: ' 38px',
                          }}
                          onClick={() => {
                            setNewTime(null);
                            setProposeNewTimeModal(true);
                          }}
                        >
                          <div className="call-provider-subtext">
                            {' '}
                            Select New Time
                          </div>
                        </DashboardEmptyButton>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="organisation-information-category-title">
                        Select one of the following times
                      </div>

                      <div className="organisation-information-category-subtext">
                        {event.organisation.first_name} provided these timeslots
                        for the session.
                      </div>

                      <div className="call-details-time-slots">
                        {event.time_slots
                          ?.filter(
                            (timeSlot: {
                              volunteer: null;
                              status: string;
                              start_time: string;
                            }) =>
                              moment(timeSlot.start_time) > moment() &&
                              timeSlot.status === 'time_slot_pending'
                          )
                          .map((timeSlot: TimeSlot) => (
                            <TimeSlotButton
                              variant="contained"
                              sx={{
                                backgroundColor:
                                  selectedTimeSlot === timeSlot.id
                                    ? 'primary'
                                    : 'transparent',
                                color:
                                  selectedTimeSlot !== timeSlot.id
                                    ? '#333'
                                    : 'contrastText',
                              }}
                              onClick={() => setSelectedTimeSlot(timeSlot.id)}
                            >
                              {displayTime(
                                timeSlot.start_time,
                                undefined,
                                `DD MMM ${defaultTimeFormat}`
                              )}
                            </TimeSlotButton>
                          ))}

                        <DashboardEmptyButton
                          variant="outlined"
                          sx={{
                            fontSize: '17px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            width: '223px',
                            borderRadius: '8px',
                            border: 'solid 1px primary',
                            height: ' 38px',
                            marginRight: '8px',
                          }}
                          onClick={() => {
                            setNewTime(null);
                            setProposeNewTimeModal(true);
                          }}
                        >
                          <div className="call-provider-subtext">
                            Select New Time
                          </div>
                        </DashboardEmptyButton>
                        {isLoading ? (
                          <Typography
                            style={{
                              fontFamily: 'Gilroy',
                              background: 'none',
                              border: 'none',
                              color: '#007BFF',
                              marginLeft: '3px',
                              marginRight: '3px',
                              fontSize: '14px',
                              fontWeight: 'normal',
                              padding: '0',
                              outline: 'none',
                            }}
                          >
                            Loading...
                          </Typography>
                        ) : messageError ? (
                          <div className="error">{messageError}</div>
                        ) : (
                          <div style={{ marginTop: '10px', fontSize: '14px' }}>
                            If you can't find a timeslot that works,
                            <button
                              onClick={handleSendMessage}
                              style={{
                                fontFamily: 'Gilroy',
                                background: 'none',
                                border: 'none',
                                color: '#007BFF',
                                marginLeft: '3px',
                                marginRight: '3px',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontSize: '14px',
                                fontWeight: 'normal',
                                padding: '0',
                                outline: 'none',
                              }}
                            >
                              click here
                            </button>
                            to message the mentee and agree a time to meet.
                          </div>
                        )}
                      </div>

                      <ProposeNewTime
                        event={event}
                        setNewTime={setNewTime}
                        setProposeNewTimeModal={setProposeNewTimeModal}
                        proposeNewTimeModal={proposeNewTimeModal}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {proposedNewTime === undefined &&
          (event.status !== 'scheduled' ||
            (event.allow_multiple_sessions &&
              !volunteerAccepted.some(
                (slot: any) => slot.volunteer_accepted === volunteerId
              ))) ? (
            <div className="call-details-step">
              <div className="cicle-counter">2</div>
              <div>
                <div className="organisation-information-category-title">
                  Choose the online meeting provider you prefer.
                  <Tooltip
                    open={showTooltip2}
                    onClick={() => setShowTooltip2(true)}
                    onClose={() => setShowTooltip2(false)}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                    placement={isMobile ? 'top-start' : 'right'}
                    title='We know that everyone has a preferred video conference platform, so we allow you to use your own call link. This gives you more control over the experience. If you prefer not to do this, select "Set up a link for me" and we will take care of the rest.'
                  >
                    <IconButton sx={{ fontSize: 'small' }}>
                      <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                    </IconButton>
                  </Tooltip>
                </div>

                <FormControl fullWidth sx={{ marginTop: '10px' }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={callProvider}
                    onChange={(event: SelectChangeEvent) => {
                      if (event.target.value === '3') setCallLink('');
                      setSelectedCallProvider(event.target.value);
                    }}
                  >
                    {callProviderDropDownOptions.map((entry) => (
                      <MenuItem
                        value={entry.value.toString()}
                        key={entry.value}
                      >
                        <div className="call-provider-subtext">
                          {entry.text}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {callProvider === '3' && (
                  <div className="jitsi-info-container">
                    <Alert severity="info">
                      Jitsi will require you to log in when you join the
                      session. It's all safe, but if you prefer, you can select
                      another provider
                    </Alert>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {callProvider?.length > 0 &&
          callProvider !== '3' &&
          proposedNewTime === undefined &&
          (event.status !== 'scheduled' ||
            (event.status === 'scheduled' && event.allow_multiple_sessions)) ? (
            <div className="call-details-step-column">
              <div className="call-details-step">
                <div className="cicle-counter">3</div>

                <div className="organisation-information-category-title">
                  Add{' '}
                  {
                    callProviderDropDownOptions.find(
                      (option) => option.value === callProvider
                    )?.text
                  }{' '}
                  link
                  <a
                    style={{
                      marginLeft: '3px',
                      fontSize: isMobile ? '14px' : '17px',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://digitalboost.org.uk/custom-link-instruction"
                  >
                    (How do I do that?)
                  </a>
                </div>
              </div>
              <div className="call-provider-helper-text">
                Copy and paste the session link into the field below. We'll
                share it with your mentee and add it to your Dashboard.
              </div>
              <TextField
                value={callLink}
                onChange={changeCallLink}
                sx={{ width: '400px', marginLeft: isMobile ? 0 : '70px' }}
                id="outlined-basic"
                variant="outlined"
              />
            </div>
          ) : null}
          {errorMessage && callProvider !== '3' ? (
            <div className="error">{errorMessage}</div>
          ) : null}
          {proposeNewTimeError ? (
            <div className="error">{proposeNewTimeError}.</div>
          ) : null}
          {timeSlotErrorMessage ? (
            <div className="error">{timeSlotErrorMessage}.</div>
          ) : null}
          {event.status !== 'scheduled' ||
          (event.allow_multiple_sessions &&
            !volunteerAccepted.some(
              (slot: any) => slot.volunteer_accepted === volunteerId
            )) ? (
            <div className="call-details-button-container">
              {newTime ? (
                <DashboardFilledButton
                  variant="contained"
                  disabled={acceptRequestInProgress}
                  sx={{ width: '182px' }}
                  onClick={() =>
                    callProvider.length < 1
                      ? setErrorMessage(
                          'Please choose your preferred online meeting provider'
                        )
                      : confirmNewProposedTime()
                  }
                >
                  {acceptRequestInProgress ? (
                    <CircularProgress size={20} sx={{ marginRight: '10px' }} />
                  ) : null}
                  Propose New Time
                </DashboardFilledButton>
              ) : (
                <DashboardFilledButton
                  variant="contained"
                  disabled={
                    selectedTimeSlot === undefined ||
                    proposedNewTime ||
                    acceptRequestInProgress
                  }
                  sx={{ width: '182px' }}
                  onClick={async () => {
                    if (callProvider.length < 1) {
                      setErrorMessage(
                        'Please choose your preferred online meeting provider'
                      );
                    } else if (
                      callProvider.length > 0 &&
                      callProvider !== '3' &&
                      !validateUrl(callLink)
                    )
                      setErrorMessage('The link you added is not valid');
                    else {
                      setAcceptRequestInProgress(true);

                      const confirmedMeeting = await confirmCall({
                        code: event.invite.code,
                        callProvider,
                        timeSlot: selectedTimeSlot?.toString() || '',
                        handleErrorMessage,
                        callUrl: callLink,
                      }).catch((error) => console.log(error));

                      if (confirmedMeeting.id)
                        setCallConfirmed(confirmedMeeting);
                      else setErrorMessage(confirmedMeeting.message);

                      setAcceptRequestInProgress(false);
                    }
                  }}
                >
                  {acceptRequestInProgress ? (
                    <CircularProgress size={20} sx={{ marginRight: '10px' }} />
                  ) : null}

                  {proposedNewTime === undefined
                    ? 'Accept Request'
                    : 'Waiting For Reply'}
                </DashboardFilledButton>
              )}

              {isDirectedRequest ? (
                <DashboardEmptyButton
                  sx={{ color: 'black', width: '182px' }}
                  onClick={() =>
                    navigate(`/decline-call?code=${event.invite.code}`)
                  }
                >
                  Decline Request
                </DashboardEmptyButton>
              ) : null}
            </div>
          ) : null}
          {isModalOpen ? (
            <MenteeDetailsModal
              isOpen={isModalOpen}
              event={event}
              setIsModalOpen={setIsModalOpen}
            />
          ) : null}
        </div>
        <Modal
          open={confirmationModal}
          onClose={closeConfirmation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="reschedule-box-container">
            <div className="reschedule-close-dialog-icon ">
              <IconButton
                color="primary"
                onClick={closeConfirmation}
                sx={{ padding: '0px' }}
              >
                <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
              </IconButton>
            </div>
            <div className="reschedule-card-container">
              <div className="reschedule-card-confirmation-title">
                New Time Proposed
              </div>
              <div className="reschedule-card-confirmation-message">
                We will ask {event.organisation?.first_name} if this new time is
                ok. If yes, the new time will be automatically confirmed.
                <Divider light />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
};

export default memo(NewCallDetails);
